import React from 'react'

const Contact = () => {
  return (
    <>
    <div name='contact' className='w-full h-screen bg-[#0a192f] flex justify-center items-center p-4'>
      
    <form method="POST" action='https://getform.io/f/7b6b959e-32b6-4f96-a96b-f02e02c666ee' className='max-w-[600px] flex flex-col w-full h-full'>

        <div className='max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full'>
            <p className='text-4xl font-bold inline border-b-4 border-pink-600 text-gray-300'>Contact</p>
            <p className='text-gray-300 py-4'>
                Submit the form below or send me an mail - rashup198@gmail.com
            </p>
        </div>

        <input className='bg-[#ccd6f6] p-2 placeholder:text-black' type='text' placeholder='Name' name='name' >
        </input>
        <input type='email' placeholder='Email' name='email'className='my-4 p-2 bg-[#ccd6f6] placeholder:text-black' >
        </input>
        <textarea className='placeholder:text-black bg-[#ccd6f6] p-2 ' name='message' cols='30' rows="10" placeholder='Message'>
        </textarea>

        <button className='text-white border-2 hover:bg-pink-600 hover:border-pink-600 px-4 py-2 my-8 mx-auto flex items-center rounded-md'>Send Message</button>
        
    </form>
    </div>

    <footer className=''>
    <h1 className=' pt-[30px] bg-[#0a192f] text-center text-white text-lg pb-[10px]'>Inspired by dank memes and big dreams °◡°</h1>
    <h1 className='bg-[#0a192f] text-center text-white text-lg'>© Priyanshu Pandey 2023</h1>

    </footer>
    </>
  )
}

export default Contact
